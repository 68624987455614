import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell en forma de cadira de muntar i posteriorment irregularment plegat, llis a la part superior on està disposat l’himeni de color negrós i la part inferior és més clara i estèril. Les espores són llises, el·líptiques, a vegades amb berrugues, de 15-19 x 10-12 micres. El peu de fins als 2 cm d’altura, és llis, a vegades amb solcs longitudinals, ondulat i més gruixut a la base, de color terrós a quasi negre.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      